import { JwtPayload } from 'jwt-decode';
import { FC, useEffect, useRef } from 'react';
import { ConnectedProps, batch, connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import { useJwtTokenPayload } from '@soomo/lib/hooks/index';

import CircularLoader from 'components/loaders/CircularLoader';
import { useQuery } from 'hooks/useQuery';
import { RootState } from 'store';
import {
	fetchAccessToken,
	setCourseId,
	setFullstoryEnabled,
	setUserId,
	setWebtextsBackgroundUrl,
} from 'store/actions';

type JwtAuthGatewayProps = JwtAuthGatewaysReduxProps;

/* eslint-disable camelcase */
interface OneTimeTokenPayload extends JwtPayload {
	extra: {
		webtext_cover_url: string;
		fullstory_enabled: boolean;
	};
}
/* eslint-enable camelcase */

const JwtAuthGateway: FC<JwtAuthGatewayProps> = ({
	accessToken,
	isAccessTokenLoading,
	accessTokenError,
	fetchAccessTokenRequest,
	setCourseId,
	setUserId,
	setWebtextsBackgroundUrl,
	setFullstoryEnabled,
}) => {
	const pathParams = useParams<{ courseId?: string }>();
	const queryParams = useQuery();

	const { courseId } = pathParams;
	const oneTimeToken = queryParams.get('jwt');
	const oneTimeTokenPayload = useJwtTokenPayload<OneTimeTokenPayload>(oneTimeToken || '');
	const userId = oneTimeTokenPayload?.sub;
	const fullstoryEnabled = oneTimeTokenPayload?.extra?.fullstory_enabled || false;
	const webtextsCoverUrl =
		oneTimeTokenPayload?.extra?.webtext_cover_url || '/images/welcome_splash.png';

	const isTokenRequested = useRef(false);
	const isConfigApplied = useRef(false);

	useEffect(() => {
		if (!oneTimeTokenPayload || isAccessTokenLoading || isTokenRequested.current) return;

		fetchAccessTokenRequest({ oneTimeToken });
		isTokenRequested.current = true;
	}, [oneTimeTokenPayload, oneTimeToken, isAccessTokenLoading, fetchAccessTokenRequest]);

	useEffect(() => {
		if (
			!isTokenRequested.current ||
			isAccessTokenLoading ||
			accessTokenError ||
			isConfigApplied.current
		) {
			return;
		}

		batch(() => {
			setCourseId(+courseId);
			setUserId(+userId);
			setFullstoryEnabled(fullstoryEnabled);
			setWebtextsBackgroundUrl(webtextsCoverUrl);
		});

		localStorage.setItem('userId', userId);
		localStorage.setItem('webtextsCoverUrl', webtextsCoverUrl);

		isConfigApplied.current = true;
	}, [
		isAccessTokenLoading,
		accessTokenError,
		courseId,
		userId,
		webtextsCoverUrl,
		fullstoryEnabled,
		setCourseId,
		setUserId,
		setFullstoryEnabled,
		setWebtextsBackgroundUrl,
	]);

	if (!oneTimeTokenPayload || accessTokenError) {
		return <Redirect to="/error/token-missing" />;
	}

	if (isTokenRequested.current && !isAccessTokenLoading) {
		return <Redirect from="authentication" to="welcome" />;
	}
	return <CircularLoader />;
};

const mapStateToProps = (state: RootState) => ({
	accessToken: state.accessToken,
	isAccessTokenLoading: state.async.accessToken,
	accessTokenError: state.errors.accessToken,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setCourseId,
			setUserId,
			setFullstoryEnabled,
			setWebtextsBackgroundUrl,
			fetchAccessTokenRequest: fetchAccessToken.request,
		},
		dispatch
	);

const connector = connect(mapStateToProps, mapDispatchToProps);

type JwtAuthGatewaysReduxProps = ConnectedProps<typeof connector>;

export default connector(JwtAuthGateway);
