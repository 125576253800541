import {
	ElementUnreadNotificationsResponse,
	ElementsActivityResponse,
	NotebookSummary,
	RefreshDataResponse,
} from '@soomo/lib/notebook/types/index';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { InitialNotebookAsyncState, UserInfo } from '../types';

export const setCourseId = createStandardAction('SET_COURSE_ID')<number>();
export const setUserId = createStandardAction('SET_USER_ID')<number>();
export const setFullstoryEnabled = createStandardAction('SET_FULLSTORY_ENABLED')<boolean>();
export const setWebtextsBackgroundUrl = createStandardAction('SET_BACKGROUND_URL')<string>();

export const setOverviewCategory = createStandardAction('SET_OVERVIEW_CATEGORY')<string>();
export const setActivePage = createStandardAction('SET_ACTIVE_PAGE')<string>();
export const setTocOpen = createStandardAction('SET_TOC_OPEN')<boolean>();
export const setNavbarOpen = createStandardAction('SET_NAVBAR_OPEN')<boolean>();
export const setIntercomBannerHeight = createStandardAction('SET_INTERCOM_BANNER_HEIGHT')<number>();
export const toggleToc = createStandardAction('TOGGLE_TOC')();
export const toggleTocChapterOpen = createStandardAction('TOGGLE_TOC_CHAPTER_OPEN')<string>();
export const toggleNavbar = createStandardAction('TOGGLE_NAVBAR')();

export const fetchInitialNotebookAsyncState = createAsyncAction(
	`FETCH_INITIAL_NOTEBOOK_ASYNC_STATE_REQUEST`,
	`FETCH_INITIAL_NOTEBOOK_ASYNC_STATE_SUCCESS`,
	`FETCH_INITIAL_NOTEBOOK_ASYNC_STATE_FAILURE`
)<string, InitialNotebookAsyncState, Error>();

export const fetchNotebookSummary = createAsyncAction(
	`FETCH_NOTEBOOK_SUMMARY_REQUEST`,
	`FETCH_NOTEBOOK_SUMMARY_SUCCESS`,
	`FETCH_NOTEBOOK_SUMMARY_FAILURE`
)<null, NotebookSummary, Error>();

export const refreshData = createAsyncAction(
	`REFRESH_DATA`,
	`REFRESH_DATA_SUCCESS`,
	`REFRESH_DATA_FAILURE`
)<null, RefreshDataResponse, Error>();

export const fetchElementsActivity = createAsyncAction(
	`FETCH_ELEMENTS_ACTIVITY_REQUEST`,
	`FETCH_ELEMENTS_ACTIVITY_SUCCESS`,
	`FETCH_ELEMENTS_ACTIVITY_FAILURE`
)<
	string,
	ElementsActivityResponse,
	{
		pageId: string;
		error: string;
	}
>();

export const fetchAccessToken = createAsyncAction(
	`FETCH_ACCESS_TOKEN_REQUEST`,
	`FETCH_ACCESS_TOKEN_SUCCESS`,
	`FETCH_ACCESS_TOKEN_FAILURE`
)<{ oneTimeToken: string }, string, Error>();

export const fetchCurrentUserData = createAsyncAction(
	`FETCH_USER_DATA_REQUEST`,
	`FETCH_USER_DATA_SUCCESS`,
	`FETCH_USER_DATA_FAILURE`
)<null, UserInfo, Error>();

export const fetchElementUnreadNotifications = createAsyncAction(
	`FETCH_ELEMENT_UNREAD_NOTIFICATIONS_REQUEST`,
	`FETCH_ELEMENT_UNREAD_NOTIFICATIONS_SUCCESS`,
	`FETCH_ELEMENT_UNREAD_NOTIFICATIONS_FAILURE`
)<
	string,
	ElementUnreadNotificationsResponse,
	{
		elementFamilyId: string;
		error: string;
	}
>();
