import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import { SAQuestionAnswer } from 'types';
import { SAQuestionElement } from '@soomo/lib/types/WebtextManifest';
import { Characters } from '@soomo/lib/utils/index';
import { formatTimestamp } from '@soomo/lib/formatters';
import { ActivityHistory, VersionHistoryItem } from '@soomo/lib/notebook/components/index';

import NotebookInstructorFeedback from 'components/page_view/elements/NotebookInstructorFeedback';
import elementStyles from 'components/page_view/elements/element_styles';
import { useAppSelector } from 'store/index';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

import IconCorrectness from '../IconCorrectness';
import UnseenCommentRedirect from '../UnseenCommentRedirect';

interface SAQuestionProps {
	element: SAQuestionElement;
	answer: SAQuestionAnswer;
	elementActivity?: any;
}

const SAQuestion: FC<SAQuestionProps> = (props) => {
	const { element, answer, elementActivity } = props;

	const userId = useAppSelector((state) => state.userId);

	const header = useMemo(() => {
		let timeStamp = '';
		if (answer) {
			const lastSaved = formatTimestamp(answer.saved_at);
			const postedAt = answer.posted_at ? `| POSTED ${formatTimestamp(answer.posted_at)}` : '';
			timeStamp = ` ${Characters.emdash} LAST SAVED ${lastSaved} ${postedAt}`;
		}

		return (
			<div className="element-activity-header">
				<span className="element-type">Short Answer</span>
				{timeStamp}
				{elementActivity.past_due && ` ${Characters.emdash} PAST DUE`}
			</div>
		);
	}, [answer, elementActivity.past_due]);

	const postedEventCount = useMemo(
		() => answer?.history?.filter(({ event }) => event === 'posted')?.length || 0,
		[answer?.history]
	);

	return (
		<div className={cn('page-element short-answer-question-element', elementStyles)}>
			<div className="element-content">
				{header}
				<h2 className="element-body" dangerouslySetInnerHTML={{ __html: element.body }} />
				<div className="element-results open-ended">
					{answer && answer.posted_at && <IconCorrectness />}
					{answer && !answer.posted_at && <span>Draft saved</span>}
				</div>
				<div className={cn('element-response', { completed: answer != null })}>
					{answer ? (
						<p dangerouslySetInnerHTML={{ __html: answer.body }} />
					) : (
						<p className="no-answer">Not yet answered</p>
					)}
				</div>
				{answer && answer.posted_at && answer.reference_answer && (
					<div className="reference-answer">
						<b>Reference Answer:</b>
						<p dangerouslySetInnerHTML={{ __html: answer.reference_answer }} />
					</div>
				)}
				<NotebookInstructorFeedback comments={elementActivity.comments} />
				{answer?.history && postedEventCount > 0 && (
					<ActivityHistory attemptCount={postedEventCount}>
						{answer.history
							.filter((v) => v.event !== 'saved')
							.map((item, index) => (
								<VersionHistoryItem
									key={`${item.event}-${index}`}
									version={item}
									studentId={userId}
									showSeenCommentStatus={false}
									concealedCommentContent={
										<UnseenCommentRedirect elementFamilyId={element.family_id} />
									}
								/>
							))}
					</ActivityHistory>
				)}
			</div>
		</div>
	);
};

export default withLibsThemeProvider(SAQuestion);
