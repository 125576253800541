import React, { FC, ReactElement } from 'react';
import { NotebookPageSummary } from '@soomo/lib/notebook/types/index';
import cn from 'classnames';
import { visuallyHidden } from 'styles/utils';
import styles from './styles';
import { formatTimeSpent } from '@soomo/lib/formatters';

interface TimingTableBoxProps {
	pageSummary: NotebookPageSummary;
}

enum TimingElementTypeAriaLabel {
	'complete' = 'Time on page spent clicking, typing, and scrolling',
	'not-attempted' = 'Page not yet visited',
}

const TimingTableBox: FC<TimingTableBoxProps> = ({ pageSummary }) => {
	const { pageOpened, totalTimeSpent } = pageSummary;

	const pageType = pageOpened ? 'complete' : 'not-attempted';

	let element: ReactElement | string = null;
	switch (pageType) {
		case 'complete':
			element = formatTimeSpent(totalTimeSpent);
			break;
		case 'not-attempted':
			element = <div className="line" />;
			break;
	}

	return (
		<div className={cn(styles, pageType)}>
			{element}
			<span className={visuallyHidden}>{TimingElementTypeAriaLabel[pageType]}</span>
		</div>
	);
};

export default TimingTableBox;
