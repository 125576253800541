import React, { FC } from 'react';
import { isEmpty } from 'lodash-es';
import { courseHasLimitedAttempts } from '@soomo/lib/notebook/utils/index';

import ProgressTableBox from 'components/tables/ProgressTableBox';
import styles from 'components/legends/styles';
import { useAppSelector } from 'store';

export const basePage = {
	id: '',
	name: '',
	number: 0,
	questionsCompleted: 0,
	questionsCorrect: 0,
	questionsPossible: 0,
	questionsScored: 0,
	questionsGraded: 0,
	questionsInstructorGradable: 0,
	totalTimeSpent: 0,
	lastActivityAt: null,
	lastSavedAt: null,
	pastDue: false,
	pageOpened: true,
	dueAt: null,
	penaltyPeriodEndsAt: null,
	pastDuePenaltyFactor: null,
};

export const complete = {
	...basePage,
	questionsCorrect: 1,
	questionsScored: 1,
};

export const incomplete = {
	...basePage,
	questionsCompleted: 2,
	questionsPossible: 4,
};

const attemptedPastDue = {
	...basePage,
	questionsCompleted: 2,
	questionsPossible: 4,
	pastDue: true,
};

const notAttempted = {
	...basePage,
	questionsPossible: 1,
	pageOpened: false,
};

const notAttemptedPastDue = {
	...basePage,
	questionsPossible: 1,
	pageOpened: false,
	pastDue: true,
};

const ProgressViewLegend: FC = () => {
	const notebookSummary = useAppSelector((state) => state.notebookSummary);
	const coursePolicy = useAppSelector((state) => state.coursePolicy);

	const dueDatesPresent = !isEmpty(notebookSummary.dueDates);
	const limitedAttempts = coursePolicy ? courseHasLimitedAttempts(coursePolicy) : false;

	return (
		<div className={styles}>
			<span>
				<ProgressTableBox pageSummary={complete} />
				<span>Complete</span>
			</span>
			<span>
				<ProgressTableBox pageSummary={incomplete} />
				<span>Incomplete</span>
			</span>
			<IncompletableAttemptedCell
				dueDatesPresent={dueDatesPresent}
				limitedAttempts={limitedAttempts}
			/>
			<span>
				<ProgressTableBox pageSummary={notAttempted} />
				<span>Page not yet visited</span>
			</span>
			<IncompletableNotAttemptedCell dueDatesPresent={dueDatesPresent} />
		</div>
	);
};

const IncompletableAttemptedCell: FC<{
	dueDatesPresent: boolean;
	limitedAttempts: boolean;
}> = ({ dueDatesPresent, limitedAttempts }) =>
	dueDatesPresent || limitedAttempts ? (
		<>
			<ProgressTableBox pageSummary={attemptedPastDue} />
			<span>
				{limitedAttempts && <span className="no-wrap">No attempts remain</span>}
				{limitedAttempts && dueDatesPresent && <br />}
				{dueDatesPresent && <span className="no-wrap">Due date has passed</span>}
			</span>
		</>
	) : null;

const IncompletableNotAttemptedCell: FC<{ dueDatesPresent: boolean }> = ({
	dueDatesPresent,
}) =>
	dueDatesPresent ? (
		<span>
			<ProgressTableBox pageSummary={notAttemptedPastDue} />
			<span>
				<span className="no-wrap">Due date has passed</span>
			</span>
		</span>
	) : null;

export default ProgressViewLegend;
