import { FC } from 'react';
import { toggleNavbar } from 'store/actions';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectStudentFullname } from '../../store/selectors';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { RaisedButton } from 'components';
import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { API_HOST } from '../../constants';
import styles from './styles';
import { useMediaBreakpoint } from '@soomo/lib/hooks/index';

const Header: FC = () => {
	const dispatch = useAppDispatch();

	const course = useAppSelector((state) => state.course);
	const studentFullname = useAppSelector(selectStudentFullname);

	const handleToggleNavbar = () => dispatch(toggleNavbar());

	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');

	return (
		<AppBar position="static" classes={{ root: styles }}>
			<Toolbar variant="dense" classes={{ root: 'toolbar' }}>
				{!isMediumScreen && (
					<>
						<RaisedButton
							variant="text"
							component={Link}
							to="overview"
							startIcon={<AppsIcon />}
							classes={{ root: 'home' }}
						>
							My Progress
						</RaisedButton>
						<span className="slash">/</span>
					</>
				)}
				{isMediumScreen && (
					<IconButton
						aria-label="toggle table of content menu"
						onClick={handleToggleNavbar}
						classes={{ root: 'toc-toggle-button' }}
					>
						<MenuIcon />
					</IconButton>
				)}
				<RaisedButton
					variant="text"
					href={`${API_HOST}/courses/${course.id}`}
					target="_blank"
					classes={{ root: 'course-name' }}
				>
					{course.name}
				</RaisedButton>
				{!isMediumScreen && (
					<>
						<span className="slash">/</span>
						<span className="student-name">{studentFullname}</span>
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
