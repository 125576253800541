import Button from '@mui/material/Button';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setNavbarOpen, toggleToc } from '../../../store/actions';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popover from '@mui/material/Popover';
import NudgeArrow from '../../NudgeArrow';
import { history } from '../../../navigation/Routing';
import TableOfContentsMenu from '../../TableOfContentsMenu';
import { getPageIdsForManifest, notebookLinkForPage } from '@soomo/lib/notebook/utils/index';
import { pageLabel } from '../../../store/selectors';
import { popoverSelector } from '../styles';
import { useMediaBreakpoint } from '@soomo/lib/hooks/index';

const ScopeSelector: FC = () => {
	const dispatch = useAppDispatch();

	const activePage = useAppSelector((state) => state.activePage);
	const tocOpen = useAppSelector((state) => state.tocOpen);
	const manifest = useAppSelector((state) => state.manifest);

	const pagesIds = useMemo(() => getPageIdsForManifest(manifest), [manifest]);
	const activePageIndex = pagesIds.indexOf(activePage);

	const selectedItemLabel = useMemo(() => {
		const page = manifest.pages[activePage];
		if (!activePage || !page) return 'Select Page';

		return pageLabel(page);
	}, [activePage, manifest]);

	const { prevArrowDisabled, nextArrowDisabled } = useMemo(() => {
		if (!activePage) {
			return { prevArrowDisabled: true, nextArrowDisabled: true };
		}

		const firstPageId = pagesIds[0];
		if (activePage === firstPageId) {
			return { prevArrowDisabled: true };
		}

		const lastPageId = pagesIds[pagesIds.length - 1];
		if (activePage === lastPageId) {
			return { nextArrowDisabled: true };
		}

		return {};
	}, [activePage, pagesIds]);

	const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLButtonElement | null>(null);

	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');

	const handleMenuToggle = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		dispatch(toggleToc());
		setMenuAnchorElement((currentAnchor) => (currentAnchor ? null : event.currentTarget));
	};

	const closeMenu = () => {
		if (!tocOpen) return;

		dispatch(toggleToc());
		setMenuAnchorElement(null);
	};

	const handlePageSelect = (pageId: string) => {
		closeMenu();
		if (isMediumScreen) dispatch(setNavbarOpen(false));

		const pageLink = notebookLinkForPage(pageId);
		history.push(pageLink);
	};

	const handleDirectionalPageSelect = (direction: 'previous' | 'next') => {
		const targetPageIndex =
			direction === 'previous'
				? Math.max(activePageIndex - 1, 0)
				: Math.min(activePageIndex + 1, pagesIds.length - 1);
		const targetPageId = pagesIds[targetPageIndex];
		handlePageSelect(targetPageId);
	};

	return (
		<div className={popoverSelector}>
			<Button
				onClick={handleMenuToggle}
				classes={{
					root: 'selector-label-button',
				}}
			>
				<div className="selector-label-box">
					<span className="selector-label">Table of Contents</span>
					<div className="selector-label-indicators">
						<div className="selector-label-arrow-box">
							<ChevronRightIcon sx={{ fontSize: 28 }} />
						</div>
						<div data-active={tocOpen} className="selector-label-indicator">
							&nbsp;
						</div>
						{/* Need &nbsp; to give height in Safari */}
					</div>
				</div>
			</Button>

			<Popover
				open={tocOpen}
				anchorEl={menuAnchorElement}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				onClose={closeMenu}
				PaperProps={{ square: true }}
			>
				<TableOfContentsMenu onPageSelect={handlePageSelect} />
			</Popover>

			<div className="selection-label-box">
				<span
					className={'selection-label-text'}
					dangerouslySetInnerHTML={{ __html: selectedItemLabel }}
				/>
				<div className="nudge-arrows-box">
					<NudgeArrow
						direction="back"
						onClick={() => handleDirectionalPageSelect('previous')}
						disabled={prevArrowDisabled}
					/>
					<NudgeArrow
						direction="forward"
						onClick={() => handleDirectionalPageSelect('next')}
						disabled={nextArrowDisabled}
					/>
				</div>
			</div>
		</div>
	);
};

export default ScopeSelector;
