import React, { ChangeEvent, FC, useRef, useContext } from 'react';
import { FaArrowRight, FaCheck as Check } from 'react-icons/fa';
import { useUserRegistry } from '@soomo/lib/hooks/index';
import { Link, Redirect } from 'react-router-dom';
import { RaisedButton } from 'components';
import { useAppSelector } from '../../store';
import ProgressLoader from '../../components/loaders/ProgressLoader';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './styles';

export const WelcomeContext = React.createContext({
	isAppLoading: true,
	appError: false,
});

const WelcomeScreen: FC = () => {
	const { isAppLoading, appError } = useContext(WelcomeContext);
	const courseId = useAppSelector((state) => state.courseId);

	const [showWelcomeScreen, setShowWelcomeScreen] = useUserRegistry(
		`course:${courseId}:student_notebook:show_welcome_screen`,
		true,
		window.Sm.userRegistry
	);

	const webtextsBackgroundUrl = useAppSelector((state) => state.webtextsCoverUrl);

	const initialShowWelcomeScreen = useRef(showWelcomeScreen);
	if (!initialShowWelcomeScreen.current) {
		return <Redirect to="overview" />;
	}

	const onToggleShowWelcome = (event: ChangeEvent<HTMLInputElement>) =>
		setShowWelcomeScreen(!event.target.checked);

	return (
		<div className={styles} style={{ backgroundImage: `url(${webtextsBackgroundUrl})` }}>
			<div className="welcome-card">
				<h1>Welcome</h1>
				<h3>Two ways you can make sure you’re doing your best work:</h3>

				<div className="welcome-card-body">
					<div className="progress-tip">
						<div className="progress-icon">
							<Check size={32} color="#668FFC" aria-hidden />
						</div>
						<h2>Check your progress:</h2>
						<p>Make sure you haven’t forgotten to answer any of the questions.</p>
					</div>
					<div className="vertical-line" />
					<div className="score-tip">
						<div className="score-icon">100%</div>
						<h2>Check your score:</h2>
						<p>Make sure you haven’t forgotten to go back and reset any incorrect answers.</p>
					</div>
				</div>
				{!isAppLoading ? (
					appError ? (
						<div className="error-message">
							<p>{appError.toString()}</p>
						</div>
					) : (
						<div className="user-inputs">
							<RaisedButton
								component={Link}
								to={`/courses/${courseId}/overview`}
								endIcon={<FaArrowRight size={14} aria-hidden />}
								classes={{ root: 'begin-button' }}
							>
								Begin
							</RaisedButton>
							<FormControlLabel
								control={
									<Checkbox
										classes={{
											root: 'welcome-screen-toggle',
											checked: 'welcome-screen-toggle-checked',
										}}
									/>
								}
								checked={!showWelcomeScreen}
								onChange={onToggleShowWelcome}
								classes={{ label: 'welcome-screen-toggle-label' }}
								label="Please don’t show me this again"
							/>
						</div>
					)
				) : (
					<ProgressLoader barClassName="progress-bar" className="welcome-loader" />
				)}
			</div>
		</div>
	);
};

export default WelcomeScreen;
