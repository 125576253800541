import React from 'react';
import cn from 'classnames';
import { Box } from '@mui/material';

import { PollQuestionAnswer } from '@soomo/lib/notebook/types/index';
import { PollQuestionElement as ManifestPollQuestionElement } from '@soomo/lib/types/WebtextManifest';
import { QuestionChoices } from '@soomo/lib/components/shared/Question/index';
import { Characters } from '@soomo/lib/utils/index';
import { formatTimestamp, formatTimestampShort } from '@soomo/lib/formatters';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';
import elementStyles from 'components/page_view/elements/element_styles';
import PollResults from './poll_results';
import IconCorrectness from '../IconCorrectness';

interface Props {
	elementActivity?: any;
	element: ManifestPollQuestionElement;
	answer: PollQuestionAnswer;
	tabsUi: boolean;
}

const PollQuestionElement = (props: Props) => {
	const { element, answer, tabsUi } = props;

	const renderHeader = () => {
		const timestamp =
			!tabsUi &&
			answer &&
			` ${Characters.emdash} COMPLETED ${formatTimestamp(answer.saved_at)}`;

		return (
			<div className="element-activity-header">
				<span className="element-type">Poll Question</span>
				{timestamp}
				{props.elementActivity.past_due && ` ${Characters.emdash} PAST DUE`}
			</div>
		);
	};

	const renderNoAnswer = () =>
		tabsUi ? (
			<QuestionChoices
				questionFamilyId={element.family_id}
				choices={element.choices}
				disabled
				className="question-choices"
			/>
		) : (
			<p className="no-answer">Not yet answered</p>
		);

	const renderAnswer = () =>
		tabsUi ? (
			<>
				<Box mt={2} fontSize={12} color="#686868">
					COMPLETED
				</Box>
				<Box mt={2}>To review the poll results, go to the webtext page.</Box>
			</>
		) : (
			<PollResults element={element} answer={answer} />
		);

	return (
		<div className={cn('page-element poll-question-element', elementStyles)}>
			<div className="element-content">
				{renderHeader()}
				<h2 className="element-body" dangerouslySetInnerHTML={{ __html: element.body }} />
				<div className="element-results">
					{answer && (
						<>
							<IconCorrectness />
							<Box mt={4} fontSize={12} color="#686868">
								{formatTimestampShort(answer.saved_at)}
							</Box>
						</>
					)}
				</div>
				{answer ? renderAnswer() : renderNoAnswer()}
			</div>
		</div>
	);
};

export default withLibsThemeProvider(PollQuestionElement);
