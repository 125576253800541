import React from 'react';

import { default as OutlineFromLibs, OutlineProps } from '@soomo/lib/components/Outline/index';
import { OutlineVariant } from '@soomo/lib/components/Outline/types';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

type Props = Omit<OutlineProps, 'variant'>;

const Outline = (props: Props) => (
	<OutlineFromLibs {...props} variant={OutlineVariant.REVISE} />
);

export default withLibsThemeProvider(Outline);
