import React, { ComponentType, FC, ReactElement } from 'react';

import themes from '@soomo/lib/styles/themes/index';
import ThemeProvider from '@soomo/lib/styles/themes/ThemeProvider';

const withLibsThemeProvider = <P extends Record<string, any>>(
	WrappedComponent: ComponentType<P>
) => {
	// Default to blue_velvet since this is not in-context and just in My Progress
	const blueVelvetTheme = themes['blue_velvet'];

	const ComponentWithTheme: FC<P> = (props) => (
		<ThemeProvider theme={blueVelvetTheme}>
			<WrappedComponent {...props} />
		</ThemeProvider>
	);
	return ComponentWithTheme;
};

export default withLibsThemeProvider;
