import { merge } from 'lodash-es';
import {
	fetchAccessToken,
	fetchCurrentUserData,
	fetchElementUnreadNotifications,
	fetchElementsActivity,
	fetchInitialNotebookAsyncState,
	fetchNotebookSummary,
	refreshData,
	setActivePage,
	setCourseId,
	setFullstoryEnabled,
	setIntercomBannerHeight,
	setNavbarOpen,
	setOverviewCategory,
	setTocOpen,
	setUserId,
	setWebtextsBackgroundUrl,
	toggleNavbar,
	toggleToc,
	toggleTocChapterOpen,
} from 'store/actions';
import { StudentNotebookAppState } from 'types';
import { getType } from 'typesafe-actions';

const initialState: StudentNotebookAppState = {
	tocOpen: false,
	navbarOpen: true,
	overviewCategory: 'progress',
	activePage: null,
	intercomBannerHeight: 0,

	userId: +localStorage.getItem('userId') || null,
	user: null,
	fullstoryEnabled: false,

	courseId: null,
	course: null,
	manifest: null,
	coursePolicy: null,
	dueDates: null,
	grades: null,
	webtextsCoverUrl: localStorage.getItem('webtextsCoverUrl') || null,

	notebookSummary: null,

	elementsActivity: {},

	elementsUnreadNotifications: {},

	accessToken: localStorage.getItem('accessToken') || null,

	tocMenu: {
		chaptersOpen: {},
	},

	async: {
		initialState: false,
		notebookSummary: false,
		elementsActivity: {},
		elementsUnreadNotifications: {},
		accessToken: false,
		currentUserData: false,
	},
	errors: {
		initialState: false,
		notebookSummary: false,
		elementsActivity: {},
		elementsUnreadNotifications: {},
		accessToken: false,
		currentUserData: false,
	},
};

const appReducer = (state = initialState, action: any = {}) => {
	switch (action.type) {
		case getType(setCourseId):
			return {
				...state,
				courseId: action.payload,
			};
		case getType(setUserId):
			return {
				...state,
				userId: action.payload,
			};
		case getType(setFullstoryEnabled):
			return {
				...state,
				fullstoryEnabled: action.payload,
			};
		case getType(setWebtextsBackgroundUrl):
			return {
				...state,
				webtextsCoverUrl: action.payload,
			};
		case getType(setActivePage):
			return {
				...state,
				activePage: action.payload || null,
			};
		case getType(setTocOpen):
			return {
				...state,
				tocOpen: action.payload,
			};
		case getType(setNavbarOpen):
			return {
				...state,
				navbarOpen: action.payload,
				// Close the toc too
				tocOpen: action.payload === false ? false : state.tocOpen,
			};
		case getType(toggleToc):
			return {
				...state,
				tocOpen: !state.tocOpen,
			};
		case getType(toggleTocChapterOpen): {
			const chapterId = action.payload;
			return {
				...state,
				tocMenu: {
					...state.tocMenu,
					chaptersOpen: {
						...state.tocMenu.chaptersOpen,
						[chapterId]: !state.tocMenu.chaptersOpen[chapterId],
					},
				},
			};
		}
		case getType(toggleNavbar):
			return {
				...state,
				navbarOpen: !state.navbarOpen,
				// Close the toc everytime
				tocOpen: false,
			};
		case getType(setOverviewCategory):
			return {
				...state,
				overviewCategory: action.payload,
			};
		case getType(setIntercomBannerHeight):
			return {
				...state,
				intercomBannerHeight: action.payload,
			};
		case getType(fetchInitialNotebookAsyncState.request):
			return {
				...state,
				async: {
					...state.async,
					initialState: true,
				},
			};
		case getType(fetchInitialNotebookAsyncState.success):
			return {
				...state,
				...action.payload,
				errors: {
					...state.errors,
					initialState: null,
					notebookSummary: null,
				},
				async: {
					...state.async,
					initialState: false,
				},
			};
		case getType(fetchInitialNotebookAsyncState.failure):
			return {
				...state,
				errors: {
					...state.errors,
					initialState: action.payload,
				},
				async: {
					...state.async,
					initialState: false,
				},
			};

		case getType(fetchNotebookSummary.request):
			return {
				...state,
				async: {
					...state.async,
					notebookSummary: true,
				},
			};
		case getType(fetchNotebookSummary.success):
			return {
				...state,
				notebookSummary: action.payload,
				async: {
					...state.async,
					notebookSummary: false,
				},
				errors: {
					...state.errors,
					notebookSummary: null,
				},
			};
		case getType(fetchNotebookSummary.failure):
			return {
				...state,
				errors: {
					...state.errors,
					notebookSummary: action.payload,
				},
				async: {
					...state.async,
					notebookSummary: false,
				},
			};

		case getType(fetchElementsActivity.request):
			return merge({}, state, {
				async: {
					elementsActivity: { [action.payload]: true },
				},
				errors: {
					elementsActivity: { [action.payload]: null },
				},
			});
		case getType(fetchElementsActivity.success):
			return merge({}, state, {
				elementsActivity: {
					[action.payload.pageId]: action.payload.elements,
				},
				async: {
					elementsActivity: { [action.payload.pageId]: false },
				},
				errors: {
					elementsActivity: { [action.payload.pageId]: null },
				},
			});
		case getType(fetchElementsActivity.failure):
			return merge({}, state, {
				async: {
					elementsActivity: { [action.payload.pageId]: false },
				},
				errors: {
					elementsActivity: {
						[action.payload.pageId]: action.payload.error,
					},
				},
			});

		case getType(refreshData.request):
			return merge({}, state, {
				async: {
					refreshData: true,
				},
				errors: {
					refreshData: null,
				},
			});
		case getType(refreshData.success):
			return merge({}, state, {
				...action.payload,
				async: {
					refreshData: false,
				},
				errors: {
					refreshData: null,
				},
			});
		case getType(refreshData.failure):
			return merge({}, state, {
				async: {
					refreshData: false,
				},
				errors: {
					refreshData: action.payload,
				},
			});

		case getType(fetchAccessToken.request):
			return merge({}, state, {
				async: {
					accessToken: true,
				},
				errors: {
					accessToken: null,
				},
			});
		case getType(fetchAccessToken.success):
			return merge({}, state, {
				accessToken: action.payload,
				async: {
					accessToken: false,
				},
				errors: {
					accessToken: null,
				},
			});
		case getType(fetchAccessToken.failure):
			return merge({}, state, {
				async: {
					accessToken: false,
				},
				errors: {
					accessToken: action.payload,
				},
			});

		case getType(fetchCurrentUserData.request):
			return merge({}, state, {
				async: {
					currentUserData: true,
				},
				errors: {
					currentUserData: null,
				},
			});
		case getType(fetchCurrentUserData.success):
			return merge({}, state, {
				user: action.payload,
				async: {
					currentUserData: false,
				},
				errors: {
					currentUserData: null,
				},
			});
		case getType(fetchCurrentUserData.failure):
			return merge({}, state, {
				async: {
					currentUserData: false,
				},
				errors: {
					currentUserData: action.payload,
				},
			});

		case getType(fetchElementUnreadNotifications.request):
			return merge({}, state, {
				async: {
					elementsUnreadNotifications: { [action.payload]: true },
				},
				errors: {
					elementsUnreadNotifications: { [action.payload]: null },
				},
			});
		case getType(fetchElementUnreadNotifications.success):
			return merge({}, state, {
				elementsUnreadNotifications: {
					[action.payload.thingFamilyId]: action.payload.notifications,
				},
				async: {
					elementsUnreadNotifications: { [action.payload.thingFamilyId]: false },
				},
				errors: {
					elementsUnreadNotifications: { [action.payload.thingFamilyId]: null },
				},
			});
		case getType(fetchElementUnreadNotifications.failure):
			return merge({}, state, {
				async: {
					elementsUnreadNotifications: { [action.payload]: false },
				},
				errors: {
					elementsUnreadNotifications: {
						[action.payload.thingFamilyId]: action.payload.error,
					},
				},
			});
		default:
			return state;
	}
};

export default appReducer;
