import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../store';
import { setNavbarOpen } from '../../store/actions';
import QuickActionsSelector from './QuickActionsSelector';
import ScopeSelector from './ScopeSelector';
import { drawerStyles } from './styles';
import { useMediaBreakpoint } from '@soomo/lib/hooks/index';
import { Drawer } from '@mui/material';
import { css } from 'emotion';

const SelectorPanel: FC = () => {
	const dispatch = useAppDispatch();

	const navbarOpen = useAppSelector((state) => state.navbarOpen);
	const intercomBannerHeight = useAppSelector((state) => state.intercomBannerHeight);

	const isMediumScreen = useMediaBreakpoint('medium', 'max-width');
	useEffect(() => void dispatch(setNavbarOpen(!isMediumScreen)), [dispatch, isMediumScreen]);

	const [initialDrawerPaperStyle] = useState(drawerStyles.paper);

	drawerStyles.paper = css`
		${initialDrawerPaperStyle}

		margin-top: ${intercomBannerHeight}px;
	`;

	return (
		<Drawer
			open={navbarOpen}
			variant={isMediumScreen ? 'temporary' : 'permanent'}
			hideBackdrop
			elevation={1}
			ModalProps={{ keepMounted: true }}
			classes={drawerStyles}
		>
			<ScopeSelector />
			<QuickActionsSelector />
		</Drawer>
	);
};

export default SelectorPanel;
