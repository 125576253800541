import React, { useMemo, VFC } from 'react';
import cn from 'classnames';

import type { MCQuestionPoolElement } from '@soomo/lib/types/WebtextManifest';
import type { MCQuestionPoolAnswer } from '@soomo/lib/notebook/types/index';

import {
	CorrectnessIcon,
	ActivityHeader,
	ActivityHistory,
	MCQPHistoryItem,
} from '@soomo/lib/notebook/components/index';
import {
	getActiveAnswer,
	getActiveQuestion,
	getAnsweredPoolQuestions,
	getNumResets,
} from '@soomo/lib/notebook/utils/mcQuestionPool';
import { Characters } from '@soomo/lib/utils/index';

import { useAppSelector } from 'store/index';
import withLibsThemeProvider from 'hocs/withLibsThemeProvider';

import { mcDefaultLabel, Props as MCQuestionProps } from '../McQuestion';
import mcStyles from '../McQuestion/styles';
import elementStyles from '../element_styles';

interface Props extends Pick<MCQuestionProps, 'label' | 'correctChoiceFamilyId'> {
	element: MCQuestionPoolElement;
	answers: Array<MCQuestionPoolAnswer>;
	elementActivity?: any;
	attemptsAllowed?: number;
}

const MCQuestionPool: VFC<Props> = (props) => {
	const { label, element, answers, elementActivity, attemptsAllowed, correctChoiceFamilyId } =
		props;

	const userId = useAppSelector((state) => state.userId);

	const numResets = getNumResets(answers);

	const activeAnswer = getActiveAnswer(answers);
	const activeQuestion = getActiveQuestion({
		element,
		answer: activeAnswer,
		userId,
		numResets,
	});

	const reversedAnswersHistory = useMemo(() => [...answers].reverse(), [answers]);

	return (
		<div className={cn('page-element mc-question-pool-element', mcStyles)}>
			<div className={elementStyles}>
				<div className="element-content">
					<div className="element-activity-header">
						<span className="element-type">{label || mcDefaultLabel}</span>
						{elementActivity.past_due && ` ${Characters.emdash} PAST DUE`}
					</div>
					<h2
						className="element-body"
						dangerouslySetInnerHTML={{ __html: activeQuestion.body }}
					/>
					<div className="element-results">
						{activeAnswer && (
							<CorrectnessIcon className="correctness-icon" correct={activeAnswer.correct} />
						)}
					</div>
					{activeAnswer?.saved_at && (
						<ActivityHeader
							className="element-activity-body"
							timestamp={activeAnswer.saved_at}
						>
							LATEST ATTEMPT
						</ActivityHeader>
					)}
					<ul className="element-response">
						{activeQuestion.choices.map((choice, i) => {
							const choiceId = String(choice.id);

							const checked = choiceId === activeAnswer?.body;
							const correct = choiceId === correctChoiceFamilyId;
							return (
								<li
									key={choiceId}
									className={cn('mc-question-choice', { 'correct-answer': correct })}
								>
									<input id={choiceId} type="radio" checked={checked} disabled />
									<label
										htmlFor={choiceId}
										dangerouslySetInnerHTML={{ __html: choice.body }}
									/>
								</li>
							);
						})}
					</ul>
					{answers.length > 0 && (
						<ActivityHistory
							answered={!!activeAnswer}
							attemptCount={answers.length}
							attemptsAllowed={attemptsAllowed}
						>
							{reversedAnswersHistory.map((answer) => {
								const question = getAnsweredPoolQuestions(
									element.questions,
									answer.question_family_id
								);
								return <MCQPHistoryItem key={answer.id} answer={answer} question={question} />;
							})}
						</ActivityHistory>
					)}
				</div>
			</div>
		</div>
	);
};

export default withLibsThemeProvider(MCQuestionPool);
