import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import cn from 'classnames';
import { css } from 'emotion';

import Rollover from 'components/rollovers/rollover';
import { useAppSelector } from 'store';
import styles, { rowColors } from './styles';

import { GradeDeliveryTooltip } from '@soomo/lib/notebook/components/index';
import { roundGradeNumerator, roundGradeDenominator } from '@soomo/lib/notebook/utils/index';

export const tooltipTriggerAriaLabel = 'show grade delivery information';

const GradesTable: React.VFC = () => {
	const grades = useAppSelector((state) => state.grades);
	const timeZone = useAppSelector((state) => state.course.time_zone);
	const lmsInstallationId = useAppSelector((state) => state.course.lms_installation_id);
	const lmsGradebookPrecision = grades.lms_gradebook_precision;

	return (
		<div className={cn(styles, gradesTableStyles)}>
			<table>
				<thead>
					<tr>
						<th>Assigned for credit</th>
						<th>Points</th>
					</tr>
				</thead>
				<tbody>
					{grades.gradebook_columns.map((gradebookColumn, i) => {
						const studentGrade = grades.column_grades[i];
						const possiblePoints = gradebookColumn.possible_points;
						return (
							<tr key={gradebookColumn.id}>
								<td>{gradebookColumn.label}</td>
								<td data-has-lms={!!lmsInstallationId}>
									{roundGradeNumerator(
										studentGrade?.current_points ?? 0,
										lmsGradebookPrecision
									)}{' '}
									/{' '}
									{roundGradeDenominator(
										gradebookColumn.possible_points,
										lmsGradebookPrecision
									)}
									{lmsInstallationId && (
										<Rollover
											trigger="click"
											variant="none"
											content={GradeDeliveryTooltip({
												studentGrade,
												timeZone,
												possiblePoints,
												lmsGradebookPrecision,
											})}
										>
											<IconButton
												aria-label={tooltipTriggerAriaLabel}
												classes={{ root: 'lms-tooltip-trigger' }}
											>
												<InfoOutlinedIcon fontSize="inherit" />
											</IconButton>
										</Rollover>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
				<tfoot>
					<tr className={cn((grades.gradebook_columns.length + 1) % 2 === 0 ? 'even' : 'odd')}>
						<td>Total</td>
						<td>
							{roundGradeNumerator(grades.total_current_points, lmsGradebookPrecision)} /{' '}
							{roundGradeDenominator(grades.points_in_course, lmsGradebookPrecision)}
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default GradesTable;

const gradesTableStyles = css`
	table {
		td,
		th {
			&:first-child {
				text-align: start;
			}
		}
	}

	table {
		thead,
		tbody,
		tfoot {
			tr > td {
				padding: 1rem;
			}
		}
	}

	tfoot,
	tbody td:last-child {
		font-weight: bold;
	}

	tfoot tr {
		// have to set these manually because nth-of-type doesn't work across tbody + tfoot
		&.even {
			background-color: ${rowColors.even};
		}

		&.odd {
			background-color: ${rowColors.odd};
		}
	}

	td[data-has-lms='true'] {
		position: relative;
		min-width: 124px;
		padding-right: 22px;

		.lms-tooltip-trigger {
			position: absolute;
			top: 0;
			right: 0;
			width: 22px;
			height: 22px;
			font-size: 18px;
			color: inherit;
		}
	}
`;
